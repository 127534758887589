/* LearnMore.css */
.learn-more-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .learn-more-content.expanded {
    max-height: 1000px; /* Adjust this value based on the content height */
  }
  